import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { api } from './lib/api'
import { httpBatchLink } from '@trpc/client'
import { Routes } from './routes'
import { BrowserRouter } from 'react-router-dom'
import { PostHogProvider } from 'posthog-js/react'
import * as Sentry from '@sentry/react'

import { PageView } from './PageView'
import { ThemeProvider } from './components/ThemeProvider'
import { Wallet } from '@canonicxyz/wallet-sdk'
import useUser from './hooks/useUser'
import { Env } from './lib/env'
import useBalance from './hooks/useBalance'

function Providers({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(() => new QueryClient())
  const [trpcClient] = useState(() => {
    const headers = () => {
      let pubkey: string | null = null

      try {
        pubkey = Wallet.getPubKey()
      } catch {}

      const headers = pubkey ? { pubkey } : {}

      return headers
    }

    return api.createClient({
      links: [
        httpBatchLink({
          headers,
          url: '/api/trpc',
        }),
      ],
    })
  })

  return (
    <Sentry.ErrorBoundary
      fallback={(error) => (
        <div className="flex h-dvh flex-col items-center justify-center">
          <div className="text-2xl font-bold">An error occurred</div>
          <div className="text-muted-foreground text-sm">
            {error.error instanceof Error
              ? error.error.message
              : 'Unknown error'}
          </div>
        </div>
      )}
    >
      <PostHogProvider
        apiKey={
          import.meta.env.PROD
            ? 'phc_CLBc09VZ4SBtUupzsbXVdXWh8JVWEFOhcoKbJo28MzQ'
            : undefined
        }
        options={{
          capture_pageview: false,
          capture_pageleave: true,
          api_host: 'https://wallet.canonic.xyz/ingest',
        }}
      >
        <ThemeProvider>
          <BrowserRouter>
            <api.Provider client={trpcClient} queryClient={queryClient}>
              <QueryClientProvider client={queryClient}>
                {children}
              </QueryClientProvider>
            </api.Provider>
            <PageView />
          </BrowserRouter>
        </ThemeProvider>
      </PostHogProvider>
    </Sentry.ErrorBoundary>
  )
}

function App() {
  const { fetchUser, user } = useUser()
  const { fetchBalance } = useBalance()
  const [connectedWallet, setConnectedWallet] = useState(false)

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    if (Wallet.isLoggedIn() && !connectedWallet) {
      Wallet.connectWalletSse(async () => {
        await fetchBalance()
      }, !Env.PROD)

      // Wallet.connectUserSse(async () => {})

      fetchUser()
      fetchBalance()

      setConnectedWallet(true)
    }
  }, [user])

  return (
    <Providers>
      <Routes />
    </Providers>
  )
}

export default App
