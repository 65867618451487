import useUser from '@/hooks/useUser'
import { AppRoutes } from '@/routes'
import { Link } from 'react-router-dom'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import { buttonVariants } from './ui/button'

export function ProfileLink() {
  const { user, loading } = useUser()

  if (!user && !loading) {
    return (
      <Link
        to={AppRoutes.LOGIN}
        className={buttonVariants({ variant: 'outline' })}
      >
        Login
      </Link>
    )
  }

  return (
    <Link
      to={AppRoutes.PROFILE}
      className={
        'hover:border-foreground size-10 overflow-hidden rounded-full border border-transparent transition-colors'
      }
    >
      <Avatar className="h-10 w-10 rounded-full">
        <AvatarImage src={user?.image} alt={user?.paymail} />
        <AvatarFallback className="animate-pulse"></AvatarFallback>
      </Avatar>
    </Link>
  )
}
