import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '@/routes'
import useUser from '@/hooks/useUser'
import { useEffect } from 'react'
import { Authentication } from '@/components/Authentication'

export function SignupPage() {
  const { user } = useUser()

  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate(AppRoutes.BOOKMARKS)
    }
  }, [user])

  return (
    <div className="mt-10 flex flex-1 items-center justify-center sm:mt-0">
      <Authentication
        view="signup"
        onSuccess={() => navigate(AppRoutes.BOOKMARKS)}
      />
    </div>
  )
}
