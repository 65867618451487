import { AppRoutes } from './routes'
import { Navigate, Outlet } from 'react-router-dom'
import useUser from './hooks/useUser'
import { Wallet } from '@canonicxyz/wallet-sdk'
import { Loader2Icon } from 'lucide-react'

export function ProtectedLayout() {
  const { user, loading } = useUser()

  if ((!user && !loading) || !Wallet.isLoggedIn()) {
    return <Navigate to={AppRoutes.LOGIN} />
  }

  if (loading && !user)
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loader2Icon className="size-6 animate-spin" />
      </div>
    )

  return <Outlet />
}
