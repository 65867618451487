import { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { AppRoutes } from './routes'
import { Toaster } from './components/ui/toaster'
import { Search } from './components/Search'
import { FeedbackButton } from './components/FeedbackButton'
import { ThemeToggle } from './components/ThemeToggle'
import { DownloadIcon, SearchIcon } from 'lucide-react'
import { cn } from './lib/utils'
import { useDidScroll } from './hooks/useDidScroll'
import { buttonVariants } from './components/ui/button'
import { AudioPlayer } from './components/AudioPlayer'
import { ProfileLink } from './components/ProfileLink'
import useUser from './hooks/useUser'

const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent)
const isChrome = !!window.chrome

export function Layout() {
  const [showSearch, setShowSearch] = useState(false)
  const { user } = useUser()

  const didScroll = useDidScroll({ threshold: 20 })

  return (
    <div className="flex min-h-dvh w-full flex-col">
      <header
        className={cn(
          'bg-background sticky inset-x-0 top-0 z-50 flex items-center justify-between gap-2 border-b px-4 py-2 lg:px-6',
          {
            'border-border': didScroll,
            'border-transparent': !didScroll,
          },
        )}
      >
        <Link to={AppRoutes.HOME}>
          <img src={`/logo-black.png`} alt="Ark" className="h-6 dark:hidden" />
          <img
            src={`/logo-white.png`}
            alt="Ark"
            className="hidden h-6 dark:block"
          />
        </Link>
        <div className="mt-2 flex w-full flex-1 items-center justify-between sm:mt-0 sm:justify-end sm:gap-4">
          <div className="hidden flex-1 sm:flex sm:justify-center">
            <Search />
          </div>
          <nav className="flex flex-1 items-center justify-end gap-4 text-sm sm:flex-none">
            <Link to={AppRoutes.FEED}>Feed</Link>
            {user && <Link to={AppRoutes.BOOKMARKS}>Bookmarks</Link>}
            <ProfileLink />
            {/* {(isIos || isChrome) && <DownloadLink />} */}
            <button
              onClick={() => setShowSearch(!showSearch)}
              className="block sm:hidden"
            >
              <SearchIcon className="h-5 w-5" />
            </button>
          </nav>
        </div>
      </header>
      <div
        className={cn('flex-1 px-4 sm:hidden', {
          flex: showSearch,
          hidden: !showSearch,
        })}
      >
        <Search />
      </div>
      <main className="flex flex-1 flex-col px-4 pb-20 md:px-6">
        <Outlet />
      </main>
      <footer className="text-primary inline-grid grid-cols-[auto,auto] flex-col items-center justify-center gap-x-8 gap-y-4 px-4 py-4 text-sm font-medium sm:flex sm:flex-row sm:gap-4 md:px-6">
        <Link to={AppRoutes.PRIVACY_POLICY}>Privacy Policy</Link>
        <div className="hidden sm:block">|</div>
        <Link to={AppRoutes.STATS}>Stats</Link>
        <div className="hidden sm:block">|</div>
        <Link to={AppRoutes.ABOUT}>About</Link>
        <div className="hidden sm:block">|</div>
        <Link to={AppRoutes.DOWNLOADS}>Downloads</Link>
        <div className="hidden sm:block">|</div>
        <FeedbackButton />
        <ThemeToggle />
      </footer>
      <AudioPlayer />
      <Toaster />
    </div>
  )
}

const DownloadLink = () => {
  return (
    <Link
      to={AppRoutes.DOWNLOADS}
      className={buttonVariants({
        size: 'icon',
        variant: 'secondary',
        className: 'relative overflow-hidden bg-transparent',
      })}
    >
      <div className="animate-gradient from-background absolute inset-0 bg-gradient-to-tr to-indigo-300 bg-[length:200%_200%]" />
      <div className="absolute inset-0 flex items-center justify-center">
        <DownloadIcon className="h-4 w-4" />
      </div>
    </Link>
  )
}
