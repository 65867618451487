import { api } from '@/lib/api'
import React, { Fragment, useState } from 'react'
import { Input } from './ui/input'
import { useDebouncedInput } from '@/hooks/useDebouncedInput'
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover'
import { SearchIcon } from 'lucide-react'
import { Link } from 'react-router-dom'
import { AppRoutes } from '@/routes'

export function Search() {
  const {
    debouncedValue: search,
    handleInputChange,
    inputValue,
  } = useDebouncedInput({ initialValue: '', delay: 300 })

  const { data } = api.archives.browse.useInfiniteQuery(
    { status: 'all', search: search || '' },
    { getNextPageParam: (lastPage) => lastPage.nextPage },
  )

  const inputRef = React.useRef<HTMLInputElement>(null)

  const [isOpen, setIsOpen] = useState(false)

  const open =
    isOpen &&
    !!search &&
    !!data?.pages.length &&
    !!data?.pages[0].archives.length

  return (
    <Popover open={open} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <div className="relative w-full sm:max-w-sm">
          <div className="pointer-events-none absolute left-2 flex h-full items-center">
            <SearchIcon className="stroke-muted-foreground h-4 w-4" />
          </div>
          <Input
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Search..."
            className="w-full pl-8"
            ref={inputRef}
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        sideOffset={8}
        align="start"
        className="mx-4 flex max-h-[var(--radix-popover-content-available-height)] w-[var(--radix-popover-trigger-width)] max-w-xl flex-col divide-y overflow-y-auto p-0 sm:mx-0 sm:w-full"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        {data?.pages.map((group, i) => (
          <Fragment key={i}>
            {group.archives.map((a) => (
              <Link
                key={a._id}
                to={AppRoutes.buildArchiveRoute(a.url)}
                className="hover:bg-muted flex flex-col gap-1 px-4 py-2 transition-colors"
                onClick={() => setIsOpen(false)}
              >
                <div className="font-bold">
                  {a.title ?? new URL(a.url).hostname}
                </div>
                {a.excerpt && (
                  <div className="line-clamp-2 text-sm">{a.excerpt}</div>
                )}
                {a.title && (
                  <div className="text-muted-foreground truncate text-xs">
                    {a.url}
                  </div>
                )}
              </Link>
            ))}
          </Fragment>
        ))}
      </PopoverContent>
    </Popover>
  )
}
