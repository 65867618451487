import { feedbackIntegration } from '@sentry/react'
import { FlagIcon } from 'lucide-react'
import { useEffect } from 'react'
import { Button, ButtonProps } from './ui/button'

const feedback = feedbackIntegration({
  autoInject: false,
  colorScheme: 'light',
  showBranding: false,
})

interface Props {
  text?: string
  variant?: ButtonProps['variant']
}

export function FeedbackButton(props: Props) {
  const { text, variant = 'link' } = props

  useEffect(() => {
    if (import.meta.env.PROD) {
      feedback.attachTo(document.getElementById('feedback-button')!)
    }
  }, [])

  return (
    <Button
      id="feedback-button"
      variant={variant}
      className="h-auto justify-start gap-1.5 p-0 sm:justify-center"
    >
      <FlagIcon className="h-4 w-4" />
      {text || 'Report a problem'}
    </Button>
  )
}
