import { Button, buttonVariants } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'
import useBalance from '@/hooks/useBalance'
import useUser from '@/hooks/useUser'
import { cn } from '@/lib/utils'
import { AppRoutes } from '@/routes'
import { Wallet } from '@canonicxyz/wallet-sdk'
import { zodResolver } from '@hookform/resolvers/zod'
import { Loader2Icon } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { z } from 'zod'

const loginSchema = z.object({
  email: z.string().min(1).email('Invalid email address'),
  password: z.string().min(1, 'Password is required'),
})

const signupSchema = loginSchema.extend({
  confirmPassword: z.string().min(1, 'Password confirmation is required'),
})

type LoginFormValues = z.infer<typeof loginSchema>
type SignupFormValues = z.infer<typeof signupSchema>

interface Props {
  view: 'login' | 'signup'
  onSuccess?: () => void
}

export function Authentication(props: Props) {
  const { view, onSuccess } = props

  const { fetchUser } = useUser()
  const { fetchBalance } = useBalance()

  const [loading, setLoading] = useState(false)

  const form = useForm<LoginFormValues | SignupFormValues>({
    resolver: zodResolver(view === 'login' ? loginSchema : signupSchema),
    defaultValues: { email: '', password: '', confirmPassword: '' },
  })

  const onSubmit = async (data: LoginFormValues | SignupFormValues) => {
    const { email, password } = data

    if ('confirmPassword' in data) {
      if (data.confirmPassword !== data.password) {
        form.setError('confirmPassword', { message: 'Passwords do not match' })
        return
      }
    }

    setLoading(true)

    try {
      try {
        await Wallet.login({
          email,
          password,
        })
      } catch (err) {
        if (view == 'signup') {
          await Wallet.createAccount({ email, password })
        } else {
          throw err
        }
      }

      await Promise.all([fetchUser(), fetchBalance()])

      onSuccess && onSuccess()
    } catch (err) {
      const title = view === 'login' ? 'Login failed' : 'Signup failed'
      toast({
        title,
        variant: 'destructive',
        description: err instanceof Error && err.message,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex flex-1 items-center justify-center">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle>{view === 'login' ? 'Login' : 'Sign up'}</CardTitle>
          <CardDescription>
            Save archives to your account and access them from any device.
          </CardDescription>
        </CardHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <CardContent className="space-y-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input type="email" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {view === 'signup' && (
                <FormField
                  control={form.control}
                  name="confirmPassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Confirm Password</FormLabel>
                      <FormControl>
                        <Input type="password" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </CardContent>
            <CardFooter className="gap flex flex-col">
              <Button
                className="w-full"
                type="submit"
                disabled={loading}
                variant="theme"
              >
                {view === 'login' ? 'Log in' : 'Sign up'}{' '}
                {loading && <Loader2Icon className="size-4 animate-spin" />}
              </Button>
              <div className="flex items-center justify-center text-sm">
                {view === 'login'
                  ? "Don't have an account?"
                  : 'Already have an account?'}
                <Link
                  to={view === 'login' ? AppRoutes.SIGNUP : AppRoutes.LOGIN}
                  className={cn(
                    buttonVariants({
                      variant: 'link',
                      className: 'ml-1 p-0 underline underline-offset-4',
                    }),
                  )}
                >
                  {view === 'login' ? 'Sign up' : 'Login'}
                </Link>
              </div>
            </CardFooter>
          </form>
        </Form>
      </Card>
    </div>
  )
}
