import { ArchiveCard } from '@/components/ArchiveCard'
import { api } from '@/lib/api'
import { Loader2 } from 'lucide-react'
import { useInView } from 'react-intersection-observer'

export function BookmarksPage() {
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isLoading } =
    api.user.bookmarks.useInfiniteQuery(
      {},
      { getNextPageParam: ({ nextPage }) => nextPage },
    )

  const { ref: scrollRef } = useInView({
    onChange: (inView) => {
      if (inView && hasNextPage) {
        fetchNextPage()
      }
    },
  })

  const loading = isLoading || isFetchingNextPage
  const empty =
    !loading && !data?.pages.flatMap((page) => page.bookmarks).length
  return (
    <div className="mx-auto mt-10 w-full max-w-screen-lg">
      <div className="flex items-center justify-between gap-4">
        <h1 className="text-2xl font-bold">Bookmarks</h1>
      </div>
      <div className="mt-2 flex flex-col gap-2">
        {data?.pages
          .flatMap((page) => page.bookmarks)
          .map(({ archive }) => (
            <ArchiveCard key={archive.url} archive={archive} btcPriceUsd={0} />
          ))}
      </div>
      <div ref={scrollRef} />
      {loading && (
        <div className="flex items-center justify-center">
          <Loader2 className="size-6 animate-spin" />
        </div>
      )}
      {empty && (
        <p className="text-muted-foreground">
          No bookmarks added yet. Add bookmarks to easily save and find your
          favorite archives.
        </p>
      )}
    </div>
  )
}
