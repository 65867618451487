import { Output, Ticker, Wallet } from '@canonicxyz/wallet-sdk'
import { Button } from './ui/button'
import { useEffect, useState } from 'react'
import { BitcoinIcon, Loader2Icon } from 'lucide-react'
import { toast } from './ui/use-toast'
import useBalance from '@/hooks/useBalance'
import { ToastAction } from './ui/toast'
import { getTxUrl } from '@/lib/utils'
import { formatCurrency } from '@/lib/formatting'

interface Props {
  outputs: Output[]
  ticker: Ticker
  label?: string
  disabled?: boolean
  satsPerByte?: number
  showBalance?: boolean
  onSuccess?: ({
    ticker,
    txid,
    tx,
  }: {
    ticker: Ticker
    txid: string
    tx: string
  }) => void
}

let timeout: NodeJS.Timeout | null = null
let confirmationTimeout: NodeJS.Timeout | null = null

const DEBOUNCE_INTERVAL = 500
const CONFIRMATION_TIMEOUT = 5_000

export function PayButton(props: Props) {
  const {
    outputs,
    ticker,
    onSuccess,
    disabled,
    label,
    satsPerByte,
    showBalance,
  } = props

  const { balance, allUtxos } = useBalance()

  const [totalUsd, setTotalUsd] = useState<number | null>(null)
  const [tx, setTx] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [broadcasting, setBroadcasting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isWaitingForConfirmation, setIsWaitingForConfirmation] =
    useState(false)

  useEffect(() => {
    const build = async () => {
      try {
        setError(null)
        setLoading(true)

        const res = await Wallet.buildTx(ticker, outputs, satsPerByte)

        setTotalUsd(res.totalUsd)
        setTx(res.tx)
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message)
        } else {
          setError('Error')
        }
      } finally {
        setLoading(false)
      }
    }

    if (disabled) {
      setError(null)
    }

    if (balance && !disabled) {
      timeout && clearTimeout(timeout)
      timeout = setTimeout(build, DEBOUNCE_INTERVAL)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [outputs, balance, disabled, ticker])

  const broadcast = async () => {
    if (!tx) return toast({ title: 'Missing TX', variant: 'destructive' })

    try {
      setBroadcasting(true)

      const { txid } = await Wallet.broadcastTx(ticker, tx)

      toast({
        title: 'Payment Successful',
        action: (
          <ToastAction altText="View" asChild>
            <a href={getTxUrl(txid, ticker)} target="_blank" rel="noreferrer">
              View
            </a>
          </ToastAction>
        ),
        variant: 'default',
        duration: 7_000,
      })

      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3500)

      onSuccess?.({ ticker, txid, tx })

      setTx(null)
      setTotalUsd(null)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      } else {
        setError('Error')
      }
    } finally {
      setBroadcasting(false)
    }
  }

  const onClick = async () => {
    if (isWaitingForConfirmation) {
      setIsWaitingForConfirmation(false)
      await broadcast()
    } else {
      setIsWaitingForConfirmation(true)
    }
  }

  useEffect(() => {
    if (!isWaitingForConfirmation) return

    confirmationTimeout && clearTimeout(confirmationTimeout)
    confirmationTimeout = setTimeout(() => {
      setIsWaitingForConfirmation(false)
    }, CONFIRMATION_TIMEOUT)
  }, [isWaitingForConfirmation])

  return (
    <div className="text-center">
      <Button
        variant={
          error ? 'destructive' : isWaitingForConfirmation ? 'warning' : 'theme'
        }
        disabled={!tx || disabled || loading || !!error || broadcasting}
        onClick={onClick}
      >
        <BitcoinIcon className="size-5" />
        {isWaitingForConfirmation ? (
          <>
            Click again to confirm{' '}
            <Loader2Icon className="size-4 animate-spin" />
          </>
        ) : disabled ? (
          label || 'Pay'
        ) : success ? (
          'Success'
        ) : error ? (
          error
        ) : loading ? (
          <>
            Loading <Loader2Icon className="size-4 animate-spin" />
          </>
        ) : broadcasting ? (
          <>
            Sending <Loader2Icon className="size-4 animate-spin" />
          </>
        ) : totalUsd ? (
          `${label ?? 'Pay'} ${new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(totalUsd)} with ${ticker}`
        ) : (
          <>
            Loading <Loader2Icon className="size-4 animate-spin" />
          </>
        )}
      </Button>
      {showBalance && (
        <div className="text-muted-foreground mt-1 text-sm">
          Current balance: {formatCurrency(allUtxos?.[ticker]?.total_usd ?? 0)}
        </div>
      )}
    </div>
  )
}
